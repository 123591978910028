export const redirectToElement = (elementId, navigate) => {
  if (elementId) {
    const element = document.getElementById(elementId)
    if (element) {
      console.log(element)
      element?.scrollIntoView({ behavior: 'smooth' });

    } else {
      navigate(-1)
      setTimeout(() => {
        const element = document.getElementById(elementId)
        console.log(element)
        element?.scrollIntoView({ behavior: 'smooth' });

      }, 1000);

    }
  }
  else {
    navigate("/")
  }
};