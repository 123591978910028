import React from 'react'
import './index.css'
const UnderConstruction = () => {
  return (
    <div className='const-background'>

      <div className='const-container'>
        <img src='./images/under-construction.gif' alt='Under Construction' />
      </div>
    </div>
  )
}

export default UnderConstruction