
export const navLinks = [
  { id: 1, label: "Home", link: "/" },
  { id: 2, label: "About", link: "/about", element: "about" },
  { id: 3, label: "Services", link: "/services", element: "services" },
  { id: 4, label: "Contact Us", link: "contact", element: "contact" },
]

export const contactLinks = [
  { id: 1, icon: "fa-map-marker", label: "Bhiwandi, Thane MH 421302", link: "https://maps.app.goo.gl/xngadGGWQhenRxWr9" },
  { id: 2, icon: "fa-phone", label: "+91 8850411163 | +91 7977563195" },
  { id: 3, icon: "fa-envelope", label: "contact@flochemindia.com, info.flochem@gmail.com" },
]

export const socialLinks = [
  { id: 1, icon: "fa-facebook", link: "https://www.facebook.com/FLOCHEMINDIA/" },
  { id: 2, icon: "fa-twitter", link: "https://twitter.com/FlochemIndia" },
  { id: 3, icon: "fa-linkedin", link: "https://www.linkedin.com/company/flochemindia" },
  { id: 4, icon: "fa-instagram", link: "https://www.instagram.com/flochem_india" },
]

export const shortSummary = "Flochem has emerged as one of the market leaders in water treatment chemicals and requisite spare parts for machines involved in the same."

export const products = [
  {
    id: 1,
    desc: "Dosing systems are a basic need for multiple operating plants requiring fixed dosage of flocculants or other chemicals. Normally small industries opt for manual mixing and dosing of the chemicals which usually leads to wastage of chemicals and improper dosage in varying quantities. We have thus developed some simple model with easy mixing technique which enables uniform mixing and is pocket friendly to the customers seeking economical solution. On the other hand we also have fully automatic dosing system which can handle itself completely with just the push of a button (To be programmed once as per requirement). We can make customized systems too based on customer’s need.",
    imgPath: "dosing-01.png",
    title: "Dosing Systems & Agitators",
    to: "/dosing-systems"
  },
  {
    id: 2,
    desc: "Flochem is known for it’s customization of products based on customer’s need with it’s expertise in multiple sizes of PU mat panels, PU spray nozzles, PU wheels, rubber impellers and more. Also we can assist our clients with multiple other spare parts related to Mineral / Sand washing plants.",
    imgPath: "rubber-product-01.png",
    title: "PU & Rubber Products",
    to: "/pu-rubber-products"
  },
  {
    id: 3,
    desc: "We have tied up with several Motor and Pump manufacturers to cater to our customer’s need accordingly. Compatible products like Impellers and other Spares with best quality can also be provided.",
    imgPath: "motor-01.png",
    title: "Pumps, Motors & It’s Spares",
    to: "/pumps-spare-parts"
  },
]
export const carouselImages = [
  {
    id: 1,
    imgPath: "slide-01.jpg",
    alt: "Cover Photo"
  },
  {
    id: 2,
    imgPath: "slide-02.jpg",
    alt: "Cover Photo"
  },
  {
    id: 3,
    imgPath: "slide-03.jpg",
    alt: "Cover Photo"
  },

]
export const aboutDetails = {
  title: "We Are Flochem",
  desc: [
    {
      id: 1,
      p: "Flochem was founded in India by 3 environmental enthusiasts as a chemical company intended to assist clients in their path to achieve a working model wherein the industrial waste water can be reused efficiently without harming the environment and thus conserving huge amount of water. Today Flochem has emerged as one of the market leaders in water treatment chemicals and requisite spare parts for machines involved in the same."
    },
    {
      id: 2,
      p: "Some of our major products include customized Polyacrylamide based on requirement for different industrial sectors, Polyurethane products and Flocculant dosing systems. Our major strength is the timely and effective service provided by us which helped inculcate trust in our clients on Flochem."
    }
  ]

}
export const dosingSystemPoints = [
  {
    heading: "AUTOMATIC DOSING SYSTEM",
    type: "bullet-points",
    id: 1,
    img: "automatic-dosing -system.jpg",
    points: [
      {
        id: 1,
        p: "We offer Hi-Tech fully automatic dosing system with all type of customizations as per customer's need"
      },
      {
        id: 2,
        p: "Compact design and easy to operate to make sure there are no hassle in your daily plant operation"
      },
      {
        id: 3,
        p: "Ensure consistency in flocculant preparation and dosing for a cost-effective labour free model"
      }
    ]
  },
  {
    heading: "Available Customizations",
    type: "table",
    id: 2,
    img: "control-panel.jpg",
    points: [
      {
        id: 1,
        t1: "MOC :",
        t2: "SS304, SS316, PE, FRP"
      },
      {
        id: 1,
        t1: "Tank Size :",
        t2: "200 - 6000 Litre"
      },
      {
        id: 1,
        t1: "Control Panel :",
        t2: "PLC Touch panel, Push button type"
      },
      {
        id: 1,
        t1: "Pump :",
        t2: "Screw pump, Plunger pump"
      },
      {
        id: 1,
        t1: "Pump capacity :",
        t2: "100 - 6000 lph"
      },
      {
        id: 1,
        t1: "Level measurement :",
        t2: "Ultrasonic, Level Switch"
      },
    ]
  },
  {
    heading: "MANUAL DOSING SYSTEM",
    type: "bullet-points",
    id: 1,
    img: "manual-dosing-system.jpg",
    points: [
      {
        id: 1,
        p: "LLDPE CYLINDRICAL FABIRICATED PREPARATION TANK CAPACITY : 200 TO 5000 LITRES"
      },
      {
        id: 2,
        p: "AGITATOR ASSY INCLUDES FLANGE MOUNTED WITH GEAR MOTOR AND IMPELLER (MOC SS 304)"
      },
      {
        id: 3,
        p: "LEVEL GAUGE TO CHECK TANK LEVEL IN REAL TIME"
      },
      {
        id: 4,
        p: "LLDPE CYLINDRICAL FABIRICATED PREPARATION TANK CAPACITY : 200 TO 5000 LITRES"
      },
      {
        id: 5,
        p: "AGITATOR ASSY INCLUDES FLANGE MOUNTED WITH GEAR MOTOR AND IMPELLER (MOC SS 304)"
      },
      {
        id: 6,
        p: "LEVEL GAUGE TO CHECK TANK LEVEL IN REAL TIME"
      },
      {
        id: 7,
        p: "BALL VALVE AND ACCESSIORES (GASKETS, HARDWARE ETC.)"
      },
      {
        id: 8,
        p: "SKID FOR TANK AND AGITATOR (MOC: CS)"
      },
      {
        id: 9,
        p: "FLANGE FITTING AND PIPINGS ETC"
      },
      {
        id: 10,
        p: "LOW LEVEL SWITCH WITH INDICATION"
      },
      {
        id: 11,
        p: "CONTROL PANNEL WITH BUZZER"
      }
    ]
  },
  {
    heading: "CUSTOMIZED AGITATORS",
    type: "bullet-points",
    id: 1,
    img: "agitators02.jpg",
    points: [
      {
        id: 1,
        p: "We make customized agitators based on customer’s need"
      },
      {
        id: 2,
        p: "The MOC and length of the shaft can be changed as per requirement"
      },
      {
        id: 3,
        p: "No. of impellers on the shaft can be adjusted as per cutomer's desire"
      },
      {
        id: 4,
        p: "The motor and gear box for the same will be suggested and assembled as required"
      },
      {
        id: 5,
        p: "Multiple brand options for motors and gear box are available"
      }
    ]
  },
  {
    heading: "HAND - HELD AGITATORS",
    id: 1,
    img: "agitators-01.jpg",
    type: "paragraph",
    p: "Hand mixing of flocculants using rod gives inconsistent output and often results in wastage of chemicals and we have a solution just for that.Our unique hand held bearing operated manual agitator is a cost effective alternative to the labour intense hand mixing of flocculants"
  },
  {
    heading: "DOSING PUMP",
    type: "table",
    id: 2,
    img: "plung-type.jpg",
    points: [
      {
        id: 1,
        t1: "Pump",
        t2: "Plunger Type"
      },
      {
        id: 1,
        t1: "Pump Capacity",
        t2: "50 - 3000 LPH"
      },
    ]
  },
  {
    type: "table",
    id: 2,
    img: "gear-screw.jpg",
    points: [
      {
        id: 1,
        t1: "Pump",
        t2: "Gear Driven Screw Type"
      },
      {
        id: 1,
        t1: "Pump Capacity",
        t2: "300 - 6000 LPH"
      },
    ]
  },
  {
    type: "table",
    id: 2,
    img: "belt-screw.jpg",
    points: [
      {
        id: 1,
        t1: "Pump",
        t2: "Belt Driven Screw Type"
      },
      {
        id: 1,
        t1: "Pump Capacity",
        t2: "300 - 6000 LPH"
      },
    ]
  },
]

export const puProducts = [
  {
    heading: "PU & RUBBER PRODUCTS",
    id: 1,
    img: "pu1.jpg",
    type: "paragraph",
    p: "Flochem is known for it’s customization of products based on customer’s need with it’s expertise in multiple sizes of PU mat panels, PU spray nozzles, PU wheels, rubber impellers and more."
  },
  {
    // heading: "HAND - HELD AGITATORS",
    id: 1,
    img: "pu2.jpg",
    type: "paragraph",
    p: "Also we can assist our clients with multiple other spare parts related to Mineral / Sand washing plants."
  },
]

export const pumpsMotorParts = [
  {
    heading: "PUMPS, MOTORS & IT’S SPARES",
    id: 1,
    img: "pms1.jpg",
    type: "paragraph",
    p: "We have tied up with several Motor and Pump manufacturers to cater to our customer’s need accordingly."
  },
  {
    // heading: "HAND - HELD AGITATORS",
    id: 1,
    img: "pump-impellers.jpg",
    type: "paragraph",
    p: "Compatible products like Impellers and other Spares with best quality can also be provided."
  },
]

