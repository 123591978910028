import "./index.css"

const BulletPoint = ({ heading, points }) => {
  return (
    <div class="ul-text text-div">
      <h2>{heading}</h2>
      <ul>
        {
          points.map(({ id, p }) => <li key={id}> <i className="fas fa-square icon-align"></i>{p}</li>)
        }
      </ul>
    </div>
  )
}
const ParaGraphText = ({ heading, p }) => {
  return (
    <div class="para-text text-div">
      <h2>{heading}</h2>
      <p>
        {p}
      </p>
    </div>
  )
}
const TableLayout = ({ heading, columns }) => {
  return (
    <div class="table-text text-div">
      <h2>{heading}</h2>
      <table>
        {
          columns.map(({ id, t1, t2 }) => (
            <thead key={id}>
              <tr>
                <th>{t1}</th>
                <th>{t2}</th>
              </tr>
            </thead>
          ))
        }
      </table>
    </div>
  )
}
const ProductCard = (props) => {
  const imgDiv = props.type === 'table' ? 'table-img-div' : props.type === 'paragraph' ? 'para-img-div' : ''
  const direction = props.idx % 2 !== 0 ? 'direction-reverse' : ''
  return (
    <div className='product-container'>

      <div className={`product-description ${props.type} ${direction}`}>
        <div class={`${imgDiv} img-div`}>
          <img src={`./images/${props.img}`} alt={'Product Images'} />
        </div>
        {
          (() => {
            if (props.type === 'paragraph') {
              return <ParaGraphText heading={props.heading} p={props.p} img={props.img} />
            } else if (props.type === 'bullet-points') {
              return <BulletPoint heading={props.heading} points={props.points} img={props.img} />
            }
            return <TableLayout heading={props.heading} columns={props.points} img={props.img} />
          })()
        }
      </div>
    </div>
  )

}

export default ProductCard