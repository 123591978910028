import React, { useEffect, useState } from 'react'
import { socialLinks } from '../../utils/constants'
import "./index.css"
import logo from '../../assets/flochem_logo.png'
import logo_initial from '../../assets/logo_initial.png'
const ContactCard = () => {
  const [splashActive, setSplashActive] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setSplashActive(false)
    }, 3000);
  }, [])

  return (
    <div className='contact-card-container'>
      {splashActive ?
        <div class="splash-screen">
          <img src={logo} alt="Splash Logo" />
        </div> :

        <div className="contact-card">
          <div className="contact-card-header">
            <div className="header-content">
              <img src={logo_initial} alt='flochem logo' />
              <h3 className='contact-title'>FLOCHEM</h3>
            </div>
          </div>
          <div className="contact-card-body">
            <ul>
              <li>
                <i className="fas fa-mobile-alt"></i>
                <div className="li-content">
                  <a className='contact-text-header' href='tel:+918850411163'>8850411163</a>
                  <p>Mobile</p>
                  <a className="contact-text-header telephone" href="tel:+917977563195">7977563195</a>
                  <p className="telephone-text">Telephone</p>
                </div>
              </li>
              <li>
                <i className="fas fa-envelope"></i>
                <div className="li-content">
                  <a className='contact-text-header' href='mailto:contact@flochemindia.com'>contact@flochemindia.com</a>
                  <p>Email address</p>
                </div>
              </li>
              <li>
                <i className="fas fa-briefcase"></i>
                <div className="li-content">
                  <p className='contact-text-header'>Thaneer Engineering Pvt Ltd</p>
                </div>
              </li>
              <li>
                <i className="fas fa-map-marker"></i>
                <div className="li-content">
                  <a className="contact-text-header address-1" href='https://maps.app.goo.gl/xngadGGWQhenRxWr9'>Bhiwandi, Thane MH 421302</a>
                </div>
              </li>
              <li>
                <i className="fas fa-globe-americas"></i>
                <div className="li-content">
                  <a className='contact-text-header' href='https://flochemindia.com'>www.flochemindia.com</a>
                  <p>Website</p>
                </div>
              </li>
            </ul>
            <div className="download-button social-media-link">
              <a className="download-vcard" href='https://flochem.s3.eu-north-1.amazonaws.com/contact_card/vcard_flochem_.vcf'>
                <i className="fas fa-address-card"></i>
                <span className="hide-mobile">Download Vcard</span>
              </a>
            </div>
          </div>
          <div className="contact-card-footer">
            <ul>
              {socialLinks.map(({ icon, id, link }) => <a href={link} key={id}>
                <li className='social-media-link'>
                  <i className={`fab ${icon}`}></i>
                </li>
              </a>
              )}
            </ul>
          </div>
        </div>


      }

    </div>
  )
}

export default ContactCard