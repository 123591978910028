import React, { useEffect, useState } from 'react'
import About from '../../sections/About'
import Banner from '../../sections/Banner'
import Services from '../../sections/Services'
import UnderConstruction from '../../sections/UnderConstruction'
import Footer from '../Footer'
import Header from '../Header'

const Home = () => {
  const [splashActive, setSplashActive] = useState(localStorage.getItem('splash') ? false : true)
  useEffect(() => {
    setTimeout(() => {
      setSplashActive(false)
      localStorage.setItem('splash', true)
    }, 1000);
  }, [])
  return (
    <>
      {
        splashActive ?
          <div class="splash-screen">
            <img src="./images/flochem_logo.png" alt="Splash Logo" />
          </div> :
          <>
            <Header />
            <div>
              <Banner />
              <About />
              <Services />
              <UnderConstruction />
            </div>
            <Footer />
          </>
      }
    </>
  )
}

export default Home