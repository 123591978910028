import React, { useEffect, useState } from 'react'
import { aboutDetails } from '../../utils/constants'

const About = () => {
  const [description, setDescription] = useState(aboutDetails.desc.filter((d, idx) => idx === 0))
  const [showMore, setShowMore] = useState(false)
  useEffect(() => {
    setDescription(showMore ? aboutDetails.desc : aboutDetails.desc.filter((d, idx) => idx === 0))
  }, [showMore])

  return (

    <section className="about_section layout_padding" id='about'>
      <div className="container  ">
        <div className="heading_container heading_center">
          <h2>
            About <span>Us</span>
          </h2>
        </div>
        <div className="row">
          <div className="col-md-6 ">
            <div className="img-box">
              <img src="images/about-machine_01.png" alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="detail-box">

              <h3>
                {aboutDetails.title}
              </h3>
              {
                description.map(({ p, id }) => <p key={id}>{p}</p>)
              }

              <button onClick={() => setShowMore(!showMore)}>
                {!showMore ? `Read More` : `Show Less`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About