import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { navLinks } from '../../utils/constants'
import { redirectToElement } from '../../utils/helpers'
import "./index.css"
const Header = () => {
  const navigate = useNavigate()

  return (
    <header className="header_section">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg custom_nav-container ">
          <Link className="">
            <img src="images/flochem_logo.png" alt='FlochemIndia' to="/" height={"50px"} />
          </Link>
          {/* <a className="navbar-brand" href="index.html">
            <span>
              Flochem
            </span>
          </a> */}

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className=""> </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav  ">
              {navLinks.map(({ label, link, id, element }) => <li className="nav-item" key={id}>
                <button onClick={() => redirectToElement(element, navigate)} className="nav-link" >{label} <span className="sr-only">(current)</span></button>
              </li>)}

              <li className="nav-item">
                <Link className="nav-link" to=""> <i className="fa fa-user" aria-hidden="true"></i> Login</Link>
              </li>
              {/* <form className="form-inline">
                <button className="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
              </form> */}
            </ul>
          </div>
        </nav>
      </div>
    </header>)
}

export default Header