import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { contactLinks, navLinks, shortSummary, socialLinks } from '../../utils/constants'
import "./index.css"
import { redirectToElement } from '../../utils/helpers'

const Footer = () => {
  const navigate = useNavigate()
  return (

    <section className="info_section layout_padding2" id='contact'>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 info_col">
            <div className="info_contact">
              <h4>
                Address
              </h4>
              <div className="contact_link_box">
                {contactLinks.map(({ id, link, icon, label }) => <a href={link} key={id} target="_blank" rel='noreferrer'>
                  <i className={`fa ${icon}`} aria-hidden="true"></i>
                  <span>
                    {label}
                  </span>
                </a>)}

              </div>
            </div>
            <div className="info_social">
              {
                socialLinks.map(({ icon, id, link }) => <a key={id} href={link} target="_blank" rel='noreferrer'>
                  <i className={`fab ${icon}`} aria-hidden="true"></i>
                </a>)
              }
            </div>
          </div>
          {/* <div className="col-md-6 col-lg-4 info_col">
            <div className="info_detail">
              <h4>
                Info
              </h4>
              <p>
                {shortSummary}
              </p>
            </div>
          </div> */}
          <div className="col-md-6 col-lg-4 mx-auto info_col">
            <div className="info_link_box">
              <h4>
                Links
              </h4>
              <div className="info_links">
                {navLinks.map(({ id, label, link, element }) => <button key={id} onClick={() => redirectToElement(element, navigate)}>{label}</button>)}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-2 mx-auto info_col">
            <img src="images/qr-code.png" alt="flochem qr code" height={"150px"} width="150px" />
          </div>
          {/* <div className="col-md-6 col-lg-3 info_col ">
            <h4>
              Subscribe
            </h4>
            <form action="#">
              <input type="text" placeholder="Enter email" />
              <button type="submit">
                Subscribe
              </button>
            </form>
          </div> */}
        </div>
      </div>
    </section>)
}

export default Footer