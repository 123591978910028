import React from 'react'
import ProductCard from '../../components/ProductCard'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { dosingSystemPoints, pumpsMotorParts, puProducts } from '../../utils/constants'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

const Products = () => {
  const params = useParams()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  return (
    <>
      <Header />
      {
        params.productName === 'dosing-systems' && (<div>{
          dosingSystemPoints.map((ds, idx) => <ProductCard {...ds} idx={idx} />
          )
        }
        </div>)
      }
      {
        params.productName === 'pu-rubber-products' && (<div>{
          puProducts.map((ds, idx) => <ProductCard {...ds} idx={idx} />
          )
        }
        </div>)
      }
      {
        params.productName === 'pumps-spare-parts' && (<div>{
          pumpsMotorParts.map((ds, idx) => <ProductCard {...ds} idx={idx} />
          )
        }
        </div>)
      }
      <Footer />

    </>

  )
}

export default Products