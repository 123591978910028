import { Route, Routes } from 'react-router-dom';
import './App.css';
import ContactCard from './components/ContactCard';
import Home from './components/Home';
import Products from './sections/Products';
import "./style.scss";

function App() {

  return (
    <>
      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/contact-card' element={<ContactCard />} />
        <Route path='/:productName' element={<Products />} />
      </Routes>
    </>
  );
}

export default App;
