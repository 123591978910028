import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { carouselImages } from '../../utils/constants'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Banner
  = () => {
    return (
      <Carousel showArrows={true} swipeable={true} showThumbs={false}>
        {carouselImages.map(({ id, imgPath, alt }) =>
          <div key={id} className="carousel-container">
            <img src={`./images/${imgPath}`} alt={alt} className='carousel-content' />
            {/* <p className="legend">Legend 1</p> */}
          </div>
        )}


      </Carousel>
    )
  }

export default Banner
