import React from 'react'
import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import { products } from '../../utils/constants'

const Services = () => {
  return (
    <section className="service_section layout_padding" id='services'>
      <div className="service_container">
        <div className="container ">
          <div className="heading_container heading_center">
            <h2>
              Our <span>Services</span>
            </h2>
            <p>
              There are variety of services provided by <b>FLOCHEM INDIA</b>
            </p>
          </div>
          <div className="row">
            {
              products.map(({ desc, id, imgPath, title, to }) =>
                <Card alt={title} desc={desc} key={id} imgPath={imgPath} title={title} to={to} />

              )
            }

          </div>
          <div className="btn-box">
            <a href="">
              View All
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services