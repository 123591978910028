import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Card = ({ imgPath, title, desc, alt, to }) => {
  const shortDesc = desc.slice(0, 150) + "..."
  const [min, setMin] = useState(true)

  return (
    <div className="col-md-4">
      <Link to={to}>
        <div className="box ">
          <div className="img-box">
            <img src={`images/${imgPath}`} alt={alt} />
          </div>
          <div className="detail-box">
            <h5>
              {title}
            </h5>
            <p>
              {min ? shortDesc : desc}
            </p>
            <button onClick={() => setMin(!min)}>
              {min ? 'Read More' : 'Show Less'}
            </button>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card